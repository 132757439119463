<template>
  <section class="orders-header">

    <h3 class="titlePage titlePage_textLeft">
      Apply Packages
      <span class="title__img"
            @click="back"
      >
        <svg-icon name="arrow-left" class=""/>
      </span>
    </h3>

    <Form class="form" @submit="sendPackage">

      <div class="row">
        <div class="col-md-4 col-sm-6">
          <label class="form__label">
            <span class="form__labelTitle">Width</span>
            <Field type="text"
                   class="form__input"
                   name="width"
                   v-model="width"
                   :rules="{required: true, numeric: true, min_value:validateWidthMin, max_value:validateWidthMax}"
                   @input="debouncedChangeTimeRequired"
            />
              <ErrorMessage class="form__error"  name="width" />
          </label>
        </div>

        <div class="col-md-4 col-sm-6">
          <label class="form__label">
            <span class="form__labelTitle">Drop</span>
            <Field type="text"
                   class="form__input"
                   name="drop"
                   v-model="drop"
                   :rules="'required|numeric|min_value:100|max_value:60000'"
                   @input="debouncedChangeTimeRequired"
            />
             <ErrorMessage class="form__error"  name="drop" />
          </label>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4 col-sm-6" v-for="item in packages" :key="item.id">
          <div class="set__item">
            <p class="text text_green">{{item.name}}</p>
            <div class="row">
              <div class="col-6">
                <label class="form__label">
                  <span class="form__labelTitle">Width</span>
                  <Field type="text"
                         class="form__input"
                         :name='"width " + item.name'
                         v-model="item.width"
                         :rules="'required|min_value:100|max_value:60000'"
                  />
                   <ErrorMessage class="form__error"  :name="`width ${item.name}`" />
                </label>
              </div>
              <div class="col-6">
                <label class="form__label">
                  <span class="form__labelTitle">Drop</span>
                  <Field type="text"
                         :name='"drop " + item.name'
                         class="form__input"
                         v-model="item.drop"
                         :rules="'required|min_value:100|max_value:60000'"
                  />


  <ErrorMessage class="form__error"  :name="`drop ${item.name}`" />
                </label>
              </div>
            </div>
          </div>

        </div>
      </div>
      <button class="btn btn_default"><svg-icon name="solid/step-forward" /> Next</button>
    </form>
  </section>
</template>

<script>
  import {mapState, mapGetters} from 'vuex';

  export default {
    name: "ApplyPackage",

    data() {
      return {
        width: '',
        drop: '',
      }
    },

    computed: {
      ...mapGetters({
        packages: 'applySet/packages',
        widthPrev: 'applySet/width',
        dropPrev: 'applySet/drop',
      }),

      validateWidthMin(){
        return Object.keys(this.packages).length * 100;
      },

      validateWidthMax() {
        return Object.keys(this.packages).length * 60000;
      }

      },

    methods: {
      sendPackage() {
       /* this.$validator.validate().then(valid => {
          if (valid) {*/
            this.$store.commit('applySet/updateWidthDrop', {width: this.width, drop: this.drop});
            this.$store.commit('applySet/editPackages', this.packages);
            this.$parent.package = false;
            this.$parent.color = true;
          /*}
        });*/
      },

      checkSize(){
        if(this.widthPrev !== '' && this.dropPrev !== ''){
          this.width = this.widthPrev;
          this.drop = this.dropPrev;
        }
      },

      back() {
        this.$parent.package = false;
        this.$parent.sets = true;
      },

      debouncedChangeTimeRequired: _.debounce(function (e) {
        if(e.target.name === 'width' && e.target.value !== ''){
          _.forEach(this.packages, (item) => {
            item.width = Math.floor(parseFloat(e.target.value) / parseFloat(Object.keys(this.packages).length));
          })
        }
        else if(e.target.name === 'drop' && e.target.value !== '') {
          _.forEach(this.packages, (item) => {
            item.drop = parseFloat(e.target.value);
          })
        }
      }, 300),
    },

    created(){
      this.checkSize();
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../assets/scss/utils/vars";

  .form {
    &__label {
      margin-top: 0;
    }

    &__labelTitle {
      color: $navy
    }

    &__input {
      border: none;
      border-bottom: 2px solid $navy;
    }
  }

  .btn {
    margin-bottom: 10px;
  }

  .text {
    &_green {
      margin-bottom: 0;
    }
  }

  .set__item {
    margin: 0;
    margin-bottom: 20px;
    flex: none;
  }

  .title {
    &Page {
      position: relative;
    }
    &__img {
      right: 0;
      left: auto;
    }
  }
</style>
